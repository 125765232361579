import styled from "styled-components";
import background from "../../../assets/images/background-about.png";
import { COLORS } from "../../../theme";

export const ContactResume = styled.div`
  padding: 112px 0;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-top: 48px;
    color: ${COLORS.WHITE};
    line-height: 28px;
  }

  ul {
    list-style-position: inside;
    padding: 12px 0;
  }

  li {
    padding: 4px 0;
  }
`;
