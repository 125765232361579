import Research from "../../../assets/images/research.svg";
import Suport from "../../../assets/images/suport.svg";
import Development from "../../../assets/images/development.svg";
import Section from "../../../components/Section";
import { Cards } from "./style";
import SolutionCard from "./solutionCard";

export default function Solutions({ referance }: SectionsProps) {
  return (
    <Section referance={referance}>
      <Cards>
        <SolutionCard
          title="Consultoria"
          description="Consultoria tecnológica personalizada"
          image={Research}
        />
        <SolutionCard
          title="Suporte"
          description="Suporte técnico personalizado e confiável"
          image={Suport}
        />
        <SolutionCard
          title="Desenvolvimento"
          description="Desenvolvimento sob medida de soluções tecnológicas"
          image={Development}
        />
      </Cards>
    </Section>
  );
}
