import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Privacy from "./pages/privacy";
import Cookies from "./components/Cookies";

const queryClient = new QueryClient();

const acceptedCookies = localStorage.getItem("acceptedCookies") === "true";

function App() {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ToastContainer
          position="top-right"
          theme="colored"
          hideProgressBar
          autoClose={3000}
        />
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/politica-privacidade" Component={Privacy} />
          <Route path="*" Component={Home} />
        </Routes>
        {!acceptedCookies && <Cookies />}
      </QueryClientProvider>
    </BrowserRouter>
  );
}

export default App;
