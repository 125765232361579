import { Wpp } from "./style";
import WppImage from "../../assets/images/whatsapp.png";

export default function WhatsappButton() {
  return (
    <Wpp
      href="https://api.whatsapp.com/send?phone=5527999792051"
      target="_blank"
    >
      <img src={WppImage} alt="whatsapp icon" />
    </Wpp>
  );
}
