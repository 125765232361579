import { styled } from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../../theme";
import { Link } from "react-router-dom";

export const Cards = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1050px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Card = styled.div`
  width: 328px;
  height: 244px;
`;

export const CardContent = styled.div`
  padding: 40px 48px;
  color: ${COLORS.WHITE};
`;

export const ImageCard = styled.img`
  height: 60px;
  display: flex;
  margin: 0 auto;
`;

export const CardTitle = styled.p`
  font-weight: ${FONT_WEIGHT.BOLD};
  margin-top: 32px;
  font-size: 16px;
  text-align: center;
`;

export const CardDescription = styled.p`
  font-size: 14px;
  text-align: center;
  margin-top: 8px;
`;

export const CardLink = styled(Link)`
  color: ${COLORS.SECONDARY};
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 16px;
  text-decoration: none;
`;

export const CardLinkImage = styled.img`
  margin-left: 8px;
`;
