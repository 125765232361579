import { Context } from "./style";

type TitleProps = {
  name: string;
  secondarySection?: boolean;
};

export default function Title({ name, secondarySection = false }: TitleProps) {
  return <Context secondarySection={secondarySection}>{name}</Context>;
}
