import { styled } from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../../theme";
import background from "../../../assets/images/background-contact.png";

export const ContactSection = styled.div`
  padding: 112px 0;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
`;

export const Content = styled.div``;

export const ContentForm = styled.div`
  width: 640px;

  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const Title = styled.h3`
  font-weight: ${FONT_WEIGHT.BOLD};
  color: ${COLORS.BLACK};
`;

export const Description = styled.p`
  font-size: 40px;
  font-weight: ${FONT_WEIGHT.BOLD};
  color: ${COLORS.BLACK};
`;

export const FormContainer = styled.form`
  width: 100%;
`;

export const FormDiv = styled.div`
  margin-top: 48px;
`;

export const FormContent = styled.div`
  padding-bottom: 8px;
`;

export const FormRow = styled.div`
  width: 100%;
  display: grid;
  gap: 24px;
  grid-template-columns: 308px 308px;
  padding-bottom: 12px;

  @media (max-width: 1050px) {
    display: block;
    padding-bottom: 0;
  }
`;

export const Privacy = styled.p`
  margin-bottom: 48px;
  font-size: 14px;

  a {
    color: #000;
  }
`;
