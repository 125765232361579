import {
  Content,
  CopyRight,
  Foot,
  ImageFooter,
  InfoItem,
  SectionFooter,
} from "./style";
import Logo from "../../assets/images/logo-vertical.svg";
import Phone from "../../assets/images/phone.svg";
import Location from "../../assets/images/location.svg";
import Mail from "../../assets/images/e-mail.svg";
import Instagram from "../../assets/images/instagram.svg";
import Linkedin from "../../assets/images/linkedIn.svg";
import Container from "../Container";

export default function Footer({ referance }: SectionsProps) {
  return (
    <>
      <SectionFooter>
        <Container>
          <Foot>
            <ImageFooter
              src={Logo}
              alt="tensortench logo"
              onClick={() =>
                referance?.current.scrollIntoView({
                  behavior: "smooth",
                })
              }
              loading="lazy"
            />
            <Content>
              <InfoItem>
                <img src={Phone} alt="phone" loading="lazy" />
                <p>+55 (27) 99979-2051</p>
              </InfoItem>
              <InfoItem>
                <img src={Location} alt="pin-location" loading="lazy" />
                <p>Localização em breve</p>
              </InfoItem>
              <InfoItem>
                <img src={Mail} alt="mail" loading="lazy" />
                <p>contato@tensortech.com.br</p>
              </InfoItem>
            </Content>
            <Content className="social-medias">
              <a
                href="https://www.instagram.com/tensor.tech/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={Instagram}
                  alt="Instagram"
                  loading="lazy"
                  width={32}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/tensortech-tecnologia/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Linkedin} alt="Linkedin" loading="lazy" width={32} />
              </a>
            </Content>
          </Foot>
        </Container>
      </SectionFooter>
      <CopyRight>© 2024 Tensortech. Todos os direitos reservados.</CopyRight>
    </>
  );
}
