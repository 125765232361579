import { styled } from "styled-components";
import { COLORS } from "../../theme";

type ContextProps = {
  backgroundColor?: boolean;
};

type ChildrenProps = {
  haveTitle?: boolean;
};

export const Context = styled.div<ContextProps>`
  padding: 112px 0;
  background: ${({ backgroundColor }) =>
    !backgroundColor ? COLORS.PRIMARY : COLORS.LIGHT_BLUE};
`;

export const TextCenter = styled.div`
  text-align: center;
`;

export const Description = styled.p`
  font-size: 16px;
  padding: 18px 0;
  width: 90%;
  margin: 0 auto;
`;

export const Children = styled.div<ChildrenProps>`
  margin-top: ${({ haveTitle }) => (haveTitle ? "48px" : 0)};
`;
