import { styled } from "styled-components";

export const Box = styled.div`
  width: 1050px;
  margin: 0 auto;

  @media (max-width: 1050px) {
    width: 92%;
  }
`;
