import Container from "../Container";
import {
  Button,
  Flex,
  Head,
  ImageLogo,
  Menu,
  Option,
  Options,
  Spaced,
} from "./style";
import logo from "../../assets/images/logo-horizontal.svg";
import { useEffect, useState } from "react";
import Hamburger from "../../assets/images/menu.svg";
import Close from "../../assets/images/close.svg";
import { useNavigate } from "react-router-dom";

type HeaderProps = {
  transparent?: boolean;
  notMenu?: boolean;
  topRef?: React.MutableRefObject<any>;
  solutionsRef?: React.MutableRefObject<any>;
  clientsRef?: React.MutableRefObject<any>;
  contactRef?: React.MutableRefObject<any>;
  resumeRef?: React.MutableRefObject<any>;
};

export default function Header({
  transparent,
  notMenu = false,
  topRef,
  solutionsRef,
  clientsRef,
  contactRef,
  resumeRef,
}: HeaderProps) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  function handleMenuClick(func?: React.MutableRefObject<any>) {
    const elementRect = func?.current.getBoundingClientRect();
    const scrollTop = document.documentElement.scrollTop;
    window.scrollTo({
      top: elementRect.top + scrollTop - 100,
      behavior: "smooth",
    });
    setIsOpen(false);
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const triggerHeight = solutionsRef?.current.offsetTop - 101;

      if (scrollTop > triggerHeight) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Head
      isTransparent={transparent}
      notMenu={notMenu}
      isFixed={isFixed}
      className={isFixed ? "fixed" : ""}
    >
      <Container>
        <Spaced>
          <ImageLogo
            src={logo}
            alt="tensortech logo"
            loading="lazy"
            onClick={() => {
              if (!notMenu) {
                handleMenuClick(topRef);
              } else {
                navigate("/");
              }
            }}
          />
          {!notMenu && (
            <>
              <Menu
                src={!isOpen ? Hamburger : Close}
                alt="menu"
                onClick={() => toggleMenu()}
                loading="lazy"
              />
              <Options isOpen={isOpen} isTransparent={transparent}>
                <Option onClick={() => handleMenuClick(solutionsRef)}>
                  Soluções
                </Option>
                <Option onClick={() => handleMenuClick(clientsRef)}>
                  Clientes
                </Option>
                <Option onClick={() => handleMenuClick(resumeRef)}>
                  Quem somos
                </Option>
                <Button
                  width="148px"
                  onClick={() => handleMenuClick(contactRef)}
                >
                  CONTATO
                </Button>
              </Options>
            </>
          )}
        </Spaced>
      </Container>
    </Head>
  );
}
