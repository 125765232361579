import { styled } from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../theme";

type ContextProps = {
  secondarySection: boolean;
};

export const Context = styled.p<ContextProps>`
  margin: 0 auto;
  font-size: 40px;
  font-weight: ${FONT_WEIGHT.BOLD};
  color: ${({ secondarySection }) =>
    !secondarySection ? COLORS.WHITE : COLORS.PRIMARY};

  @media (max-width: 1050px) {
    font-size: 32px;
  }
`;
