import Container from "../../../components/Container";
import Title from "../../../components/Title";
import { ContactResume, Content } from "./style";

export default function Resume({ referance }: SectionsProps) {
  return (
    <ContactResume ref={referance}>
      <Container>
        <Content>
          <Title name="Quem somos" />
          <p>
            Nós acreditamos que a tecnologia é o motor do crescimento das
            empresas. Entendemos que para uma empresa crescer e se destacar, ser
            competitiva e ter destaque nacional ela precisa de três pilares:
            <ul>
              <li>Pessoas</li>
              <li>Gestão</li>
              <li>Tecnologia</li>
            </ul>
            Por isso focamos em atrair, reter, desenvolver e desafiar as
            pessoas, acreditamos que a equipe precisa se desenvolver além das
            tecnologias atuais, aprender afundo a teoria, mesmo que não pareça
            aplicável de início, mas que irá pavimentar o caminho para a
            inovação tecnológica no curto e longo prazo.
            <br />
            Buscamos sempre eficiência operacional, bater metas é o que mantem a
            empresa e sonhos vivos. Gerir uma empresa requer energia, aproveitar
            a caminhada e comemorar cada conquista. Construir uma cultura de
            execução é o cerne de uma empresa de sucesso e indicadores
            estratégicos, táticos e operacionais são a visão do funcionamento
            dessa cultura.
          </p>
        </Content>
      </Container>
    </ContactResume>
  );
}
