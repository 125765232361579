import { styled } from "styled-components";

export const Wpp = styled.a`
  cursor: pointer;
  text-decoration: none;
  width: 54px;
  height: 54px;
  position: fixed;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  margin: 0 24px 24px 0;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);

  img {
    width: 100%;
  }
`;
