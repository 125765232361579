export const COLORS = {
  PRIMARY: "#1C3F5F",
  SECONDARY: "#E44212",
  LIGHT_BLUE: "#8ec2f236",
  RED: "#AE032C",
  WHITE: "#FFF",
  GRAY: "#E7E7E7",
  BLACK: "#333",
};


export const FONT_WEIGHT = {
  THIN: 100,
  LIGHT: 300,
  REGULAR: 400,
  MEDIUM: 500,
  SEMI_BOLD: 600,
  BOLD: 700,
  EXTRA_BOLD: 800,
  BLACK: 900,
};
