import { useState } from "react";
import Input from "../../../components/Input";
import {
  ContactSection,
  Content,
  ContentForm,
  Description,
  FormContainer,
  FormContent,
  FormDiv,
  FormRow,
  Privacy,
  Title,
} from "./style";
import Button from "../../../components/Button";
import { PhoneLengthError, RequiredFieldError } from "../../../utils/error";
import { PhoneMask } from "../../../utils/masks";
import Select from "../../../components/Select";
import TextArea from "../../../components/TextArea";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Container from "../../../components/Container";
import emailjs from "emailjs-com";
import { RefObject } from "react";

interface ContactProps {
  referance: RefObject<HTMLDivElement>;
}

export default function Contact({ referance }: ContactProps) {
  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    contact: "",
    message: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    contact: "",
    message: "",
  });

  const reasonOptions = [
    { label: "E-mail", value: "email" },
    { label: "Whatsapp", value: "whatsapp" },
    { label: "Ligação", value: "Ligação" },
  ];

  function onSubmit() {
    if (data.phoneNumber.length < 15) {
      setErrors((err) => ({
        ...err,
        phoneNumber: PhoneLengthError(data.phoneNumber),
      }));
      return;
    }

    setIsLoading(true);

    const templateParams = {
      from_name: data.name,
      from_email: data.email,
      phone: data.phoneNumber,
      contact_preference: data.contact,
      message: data.message,
    };

    emailjs
      .send(
        "service_qbxinrq",
        "template_ty99spr",
        templateParams,
        "xpeOezaog0z694gxm"
      )
      .then(
        () => {
          setData({
            name: "",
            email: "",
            phoneNumber: "",
            contact: "",
            message: "",
          });
          toast.success("Contato enviado com sucesso");
        },
        () => {
          toast.error("Erro ao enviar contato, tente novamente");
        }
      );

    setIsLoading(false);
  }

  function handleChangePhoneNumber(value: string) {
    if (value.length > 15) return;
    const phone = PhoneMask(value);
    setData((d) => ({ ...d, phoneNumber: phone }));
    setErrors((err) => ({ ...err, phoneNumber: RequiredFieldError(phone) }));
  }

  return (
    <ContactSection ref={referance}>
      <Container>
        <Content>
          <Title>Contato</Title>
          <Description>Marque uma reunião</Description>
        </Content>
        <ContentForm>
          <FormContainer
            onSubmit={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <FormDiv>
              <FormContent>
                <FormRow>
                  <Input
                    placeholder="Nome completo *"
                    value={data.name}
                    onChange={(e) => {
                      setData((d) => ({ ...d, name: e.target.value }));
                      setErrors((err) => ({
                        ...err,
                        name: RequiredFieldError(e.target.value),
                      }));
                    }}
                    error={errors.name}
                    required
                  />
                  <Input
                    placeholder="E-mail *"
                    type="email"
                    value={data.email}
                    onChange={(e) => {
                      setData((d) => ({ ...d, email: e.target.value }));
                      setErrors((err) => ({
                        ...err,
                        email: RequiredFieldError(e.target.value),
                      }));
                    }}
                    error={errors.email}
                    required
                  />
                </FormRow>
                <FormRow>
                  <Input
                    placeholder="Celular *"
                    type="text"
                    value={data.phoneNumber}
                    onChange={(e) => handleChangePhoneNumber(e.target.value)}
                    error={errors.phoneNumber}
                    required
                  />
                  <Select
                    options={reasonOptions}
                    placeholder="Preferência de contato *"
                    required
                    value={data.contact}
                    onChange={(e) =>
                      setData((d) => ({ ...d, contact: e.target.value }))
                    } // Corrigido para "contact"
                  />
                </FormRow>
                <TextArea
                  placeholder="Mensagem"
                  type="text"
                  value={data.message}
                  onChange={(e) =>
                    setData((d) => ({ ...d, message: e.target.value }))
                  }
                  error={errors.message}
                />
              </FormContent>
              <Privacy>
                Ao informar seus dados, você estará ciente das diretrizes da
                nossa{" "}
                <Link to={"/politica-privacidade"} target="_blank">
                  Política de Privacidade
                </Link>
                .
              </Privacy>
              <Button
                id="enviar-contato"
                title="Enviar"
                type="submit"
                width={"148px"}
                loading={isLoading}
              />
            </FormDiv>
          </FormContainer>
        </ContentForm>
      </Container>
    </ContactSection>
  );
}
