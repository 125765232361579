import { styled } from "styled-components";
import { COLORS } from "../../../theme";

export const Dots = styled.div`
  border-radius: 10px;
  padding: 2px 0;
`;

export const Dot = styled.ul`
  margin-bottom: -18px;

  li button:before {
    font-size: 14px;
    opacity: 1;
    color: ${COLORS.PRIMARY};
  }

  li.slick-active button:before {
    color: ${COLORS.SECONDARY};
    opacity: 1;
  }
`;

export const SlideContainer = styled.div`
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 70%;
    margin: auto;
  }
`;
