import { styled } from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../theme";
import { Context } from "../Button/style";

type HeadProps = {
  isTransparent?: boolean;
  notMenu?: boolean;
  isFixed?: boolean;
};

type OptionsProp = HeadProps & {
  isOpen?: boolean;
};

export const Head = styled.div<HeadProps>`
  width: 100%;
  padding: 2px 0;
  background-color: ${({ isTransparent, isFixed }) =>
    isTransparent && !isFixed ? "transparent" : COLORS.PRIMARY};
  position: ${({ isTransparent, isFixed }) =>
    isTransparent ? (isFixed ? "fixed" : "absolute") : "static"};
  box-shadow: ${({ isFixed }) =>
    isFixed ? "0px 0px 8px 0px rgba(0, 0, 0, 0.5)" : "none"};
  -webkit-box-shadow: ${({ isFixed }) =>
    isFixed ? "0px 0px 8px 0px rgba(0, 0, 0, 0.5)" : "none"};
  -moz-box-shadow: ${({ isFixed }) =>
    isFixed ? "0px 0px 8px 0px rgba(0, 0, 0, 0.5)" : "none"};
  z-index: 999;

  @media (min-width: 1050px) {
    margin-top: ${({ isFixed }) => (isFixed ? "-150px" : "0")};
    transform: ${({ isFixed }) => (isFixed ? "translate(0, 150px)" : "none")};
    transition: transform 0.3s;
  }

  @media (max-width: 1050px) {
    position: fixed;
    background-color: ${COLORS.PRIMARY};
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const ImageLogo = styled.img`
  width: 224px;
  cursor: pointer;
`;

export const Spaced = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
`;

export const Options = styled.div<OptionsProp>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1050px) {
    margin-top: 100px;
    z-index: 999;
    position: fixed;
    flex-direction: column;
    right: 0;
    top: 0;
    width: 100%;
    height: calc(100% - 100px);
    justify-content: flex-start;
    box-shadow: -1px 0px 3px 0px rgba(255, 255, 255, 0.5);
    -webkit-box-shadow: -1px 0px 3px 0px rgba(255, 255, 255, 0.5);
    -moz-box-shadow: -1px 0px 3px 0px rgba(255, 255, 255, 0.5);
    margin-right: ${({ isOpen }) => (isOpen ? "0px" : "-1050px")};
    transition: 0.4s all;
    z-index: 999;
    background-color: ${COLORS.PRIMARY};
  }
`;

export const Option = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${COLORS.WHITE};
  font-weight: ${FONT_WEIGHT.SEMI_BOLD};
  padding: 8px;
  margin: 2px 4px;
  font-size: 16px;
  &:hover {
    margin-bottom: 0;
    border-bottom: 2px solid ${COLORS.WHITE};
  }

  @media (max-width: 1050px) {
    width: 100%;
    border-top: 1px solid ${COLORS.GRAY};
    padding: 18px 0;

    &:first-child {
      border-top: 0;
    }

    &:hover {
      margin-bottom: 2px;
      border-bottom: 0;
    }
  }
`;

export const Button = styled(Context)`
  margin-left: 12px;
  font-size: 16px;

  @media (max-width: 1050px) {
    width: 92%;
    margin-left: 0;
  }
`;

export const Menu = styled.img`
  width: 38px;
  display: none;

  @media (max-width: 1050px) {
    display: block;
  }
`;
