import { styled } from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../../theme";
import background from "../../../assets/images/background.png";

export const ImpactSection = styled.div`
  width: 100%;
  height: 95vh;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  @media (max-width: 1370px) {
    height: 94vh;
  }

  @media (max-width: 1050px) {
    background-position: left top;
    padding-top: 1px;
    height: auto;
  }
`;

export const ImpactContent = styled.div`
  width: 600px;
  position: absolute;
  margin-top: calc((95vh - 388px) / 2);
  color: ${COLORS.WHITE};

  @media (max-width: 1370px) {
    width: calc(600px * 0.9);
  }

  @media (max-width: 1050px) {
    width: 100%;
    margin-top: 100px;
    padding: 20vh 0;
    position: static;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  @media (max-width: 800px) {
    margin-top: 80px;
    padding: 10vh 0;
  }
`;

export const ImpactTitle = styled.p`
  font-size: 48px;
  line-height: 72px;
  font-weight: ${FONT_WEIGHT.EXTRA_BOLD};

  @media (max-width: 1370px) {
    font-size: 42px;
    line-height: 64px;
  }

  @media (max-width: 1050px) {
    width: 100%;
    font-size: 40px;
    text-align: center;
  }
`;

export const ImpactDescription = styled.p`
  padding: 38px 0;
  font-size: 20px;
  line-height: 28px;

  @media (max-width: 1050px) {
    font-size: 18px;
    padding: 28px 0;
    text-align: center;
  }
`;
