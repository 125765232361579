import { styled } from "styled-components";
import { COLORS } from "../../theme";

export const SectionFooter = styled.div`
  width: 100%;
  padding: 40px 0;
  background: ${COLORS.PRIMARY};
`;

export const Foot = styled.div`
  width: 100%;
  color: ${COLORS.WHITE};
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 600px) {
    margin: 18px 0;

    &:last-of-type {
      margin-top: 0;
    }
  }

  a {
    text-decoration: none;
    margin-bottom: 18px;

    @media (max-width: 600px) {
      margin: 0 8px;
    }
  }

  @media (max-width: 600px) {
    margin-bottom: 18px;

    &.social-medias {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;

export const ImageFooter = styled.img`
  width: 140px;
  cursor: pointer;

  @media (max-width: 1370px) {
    width: 120px;
  }

  @media (max-width: 600px) {
    margin-top: 18px;
  }
`;

export const InfoItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  p {
    font-size: 16px;
  }

  @media (max-width: 600px) {
    margin-bottom: 8px;
  }
`;

export const CopyRight = styled.p`
  width: 100%;
  text-align: center;
  font-size: 12px;
  background-color: ${COLORS.WHITE};
  color: ${COLORS.BLACK};
  padding: 4px 0;
`;
