import Header from "../../components/Header";
import Solutions from "./Solutions";
import ImpactionSection from "./ImpactionSection";
import Clients from "./Clients";
import Resume from "./Resume";
import Contact from "./Contact";
import Footer from "../../components/Footer";
import { useRef } from "react";
import WhatsappButton from "../../components/WhatsappButton";

export default function Home() {
  const topRef = useRef<any>(null);
  const solutionsRef = useRef<any>(null);
  const clientsRef = useRef<any>(null);
  const contactRef = useRef<any>(null);
  const resumeRef = useRef<any>(null);

  return (
    <>
      <Header
        transparent
        topRef={topRef}
        solutionsRef={solutionsRef}
        clientsRef={clientsRef}
        contactRef={contactRef}
        resumeRef={resumeRef}
      />
      <ImpactionSection topRef={topRef} referance={contactRef} />
      <Solutions referance={solutionsRef} />
      <Clients referance={clientsRef} />
      <Resume referance={resumeRef} />
      <Contact referance={contactRef} />
      <Footer referance={topRef} />
      <WhatsappButton />
    </>
  );
}
