import { styled } from "styled-components";
import { COLORS, FONT_WEIGHT } from "../../theme";

export const Context = styled.button<ButtonProps>`
  all: unset;
  height: 48px;
  cursor: pointer;
  background: ${COLORS.SECONDARY};
  color: ${COLORS.WHITE};
  font-weight: ${FONT_WEIGHT.BOLD};
  border-radius: 4px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.5);
  transition: all 0.3s;
  font-size: 16px;
  text-align: center;
  width: ${({ width }) => width ?? "fit-content"};
  opacity: ${({ loading }) => (loading ? 0.7 : 1)};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 950px) {
    width: 100%;
    font-size: 16px;
  }
`;
